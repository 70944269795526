import Cookies from 'js-cookie';
import { publish } from '@wh/message-bus';
import { ACCOUNT_ACTION_IDS } from '@central-products/interface-account-actions';
import { feature as registrationFlowFeature } from '@central-products/registration-flow';
import { isNative, isIos } from '../../utils';

export const registrationButtonConfig = {
  type: '@sitebase/registration-button',
  data: {
    id: 'hrs-action__registration-button',
  },
  children: [],
};

const OPEN_REGISTRATION = 'wh.common.command.ui.registration.flow.start';

export const registrationPlugin = {
  beforeRender({ header }) {
    header.actions.children.push(registrationButtonConfig);
  },
  beforeBootstrap({ addBootstrapperFeature, params }) {
    const searchParams = new URLSearchParams(window.location.search);
    const nui = searchParams.get('nui');
    const livePersonFallbackUrl =
      isNative() && isIos() ? params.livePersonIosFallbackUrl : params.livePersonFallbackUrl;

    addBootstrapperFeature(registrationFlowFeature, {
      engagementName: params.engagementName,
      isLivePersonSupported: params.isLivePersonSupported,
      isNewRegistration: true,
      livePersonFallbackUrl,
      nui,
    });
  },
  postBootstrap({ bootstrapperFeatures }) {
    bootstrapperFeatures['account-actions'].onActionRequested(({ actionId }) => {
      if (actionId === ACCOUNT_ACTION_IDS.REGISTRATION_START) {
        bootstrapperFeatures['registration-flow'].start();
      }
    });

    const regilyIdenWasOpen = Cookies.get('RegilyRegistrationIden') === 'true';
    if (regilyIdenWasOpen) {
      publish(OPEN_REGISTRATION, {});
    }
  },
};
