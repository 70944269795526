import React from 'react';
import { Overlay } from '@sitebase/ui-kit';
import cx from 'classnames';

import X from '@wh/svg-icons/X';

import '@sitebase/ui-kit/styles.css';
import './main.scss';

function disableBodyScroll() {
  document.body.classList.add('cp-body-no-scroll');
}

function enableBodyScroll() {
  document.body.classList.remove('cp-body-no-scroll');
}

export class Modal extends React.Component {
  componentDidMount() {
    disableBodyScroll();
  }

  componentWillUnmount() {
    enableBodyScroll();
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { onClose, iframeUrl, outfit } = this.props;
    return (
      <Overlay className="cp-action-overlay">
        <div className={cx('cp-action-overlay__content', outfit && `cp-action-overlay__content--${outfit}`)}>
          <iframe className="cp-action-overlay__iframe" title={iframeUrl} src={iframeUrl} />

          <button onClick={onClose} className="cp-action-overlay__button">
            <X />
          </button>
        </div>
      </Overlay>
    );
  }
}
