import { feature as obsessionServiceFeature } from '@central-products/obsession-service';
import { isOpenBetSessionProxyModeEnabled } from '../../utils/isOpenBetSessionProxyModeEnabled';

export const obsessionServicePlugin = {
  beforeBootstrap({ addBootstrapperFeature, params = {} }) {
    addBootstrapperFeature(obsessionServiceFeature, {
      ...params,
      enableOAuthAuthorization: params.enableOAuthAuthorization && isOpenBetSessionProxyModeEnabled(),
    });
  },
};
