import Cookies from 'js-cookie';
import { getObLocale } from '../utils/obLocales';
import { isNative } from '../utils/isNative';

const isOpenBetSessionProxyModeEnabled = () => typeof Cookies.get('wh-ob-session-proxy-mode-enabled') !== 'undefined';

export const featuresConfigs = {
  '@feature/account-preferences': () => ({}),
  '@feature/account-info': ({ productConfig }) => ({
    withAccountBonus: productConfig.withAccountBonus,
  }),
  '@feature/live-chat-flow': ({
    productConfig,
    chatButtonName,
    chatEnvironment,
    chatIssuer,
    chatAuthFunction,
    syncAudiences,
    autoStart,
    initDelay,
    embeddedEngagements,
    allowIosNative,
    allowAndroidNative,
  }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    chatButtonName: chatButtonName,
    allowIosNative: typeof allowIosNative === 'undefined' ? false : allowIosNative,
    allowAndroidNative: typeof allowAndroidNative === 'undefined' ? false : allowAndroidNative,
    client: {
      environment: chatEnvironment,
      issuer: chatIssuer,
      authFunctionProperty: chatAuthFunction,
      syncAudiences: typeof syncAudiences === 'undefined' ? true : syncAudiences,
      autoStart: typeof autoStart === 'undefined' ? true : syncAudiences,
      embeddedEngagements,
    },
    initDelay,
  }),

  '@feature/account-flags': ({ productConfig, deviceType }) => ({
    jurisdiction: productConfig.jurisdiction,
    isMobileDevice: deviceType === 'mobile',
  }),

  '@feature/account-flags-flow': ({ productConfig, locale }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    locale,
  }),

  '@feature/promotions-flow': ({
    productConfig,
    locale,
    supportedLangCodes,
    defaultLangCode,
    helpPageUrls,
    routingStrategy,
    vertical,
    routes,
    gameLauncher,
    epicValueCarousel,
    basename,
  }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    supportedLangCodes,
    defaultLangCode,
    helpPageUrls,
    routingStrategy,
    vertical,
    routes,
    gameLauncher,
    epicValueCarousel,
    basename: typeof basename === 'undefined' ? '' : basename.replace(':langCode', locale),
  }),

  '@feature/apollo-data-access-service': ({ productConfig, vertical, enableOAuthAuthorization }) => ({
    url: enableOAuthAuthorization ? productConfig.graphqlOAuthUrl : productConfig.graphqlUrl,
    headers: ['vertical'],
    predefinedHeaders: { vertical },
    authorizationMode: enableOAuthAuthorization ? 'SessionContainerOAuthToken' : 'SessionContainerInternalToken',
  }),

  '@feature/account-actions': ({ productConfig, locale, productSource, isMobile }) => ({
    language: locale,
    jurisdiction: productConfig.jurisdiction,
    env: productConfig.env,
    regSource: productSource,
    isMobile,
  }),

  '@feature/cas-session-manager': ({ productConfig, vertical }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    casUrl: productConfig.casUrl,
    sessionLogoutListener: {
      strategy: 'ServiceStrategy',
      serviceUrl: productConfig.sessionListenerUrl,
    },
    sessionMarkActiveListener: {
      strategy: 'ServiceStrategy',
      imAliveDebounceTime: 60 * 1000,
      imAliveDebounceMaxWaitTime: 5 * 60 * 1000,
    },
    enableFlagHandling: productConfig.enableFlagHandling,
    enableRecaptcha: productConfig.enableRecaptcha,
    ...((vertical === 'sportsbook' || vertical === 'sportsbook-native') && productConfig.jurisdiction === 'com'
      ? {
          sessionLoginListener: {
            strategy: 'NativeStrategy',
            serviceUrl: `${productConfig.sessionListenerUrl}/logout`,
          },
          isNativeAppDevice: isNative(),
        }
      : {}),
  }),

  '@feature/oauth-session-manager': ({ productConfig, enableSessionSynchronization }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    casUrl: productConfig.casUrl,
    scUrl: productConfig.sessionContainerOAuthUrl,
    sessionLogoutListener: {
      strategy: 'ChannelStrategy',
      serviceUrl: productConfig.sessionContainerOAuthUrl,
    },
    sessionMarkActiveListener: {
      strategy: 'ServiceStrategy',
      imAliveDebounceTime: 60 * 1000,
      imAliveDebounceMaxWaitTime: 5 * 60 * 1000,
    },
    enableFlagHandling: productConfig.enableFlagHandling,
    enableRecaptcha: productConfig.enableRecaptcha,
    enableSessionSynchronization,
    enableSessionValidateMode: isNative(),
  }),

  '@feature/session-service': ({ productConfig, enableOAuthAuthorization }) => ({
    sessionServiceUrl: productConfig.sessionContainerUrl,
    enableSessionManagerProxyMode: enableOAuthAuthorization,
  }),

  '@feature/login-flow': ({ productConfig, locale }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    locale: locale,
  }),

  '@feature/myaccount': ({
    productConfig,
    locale,
    vertical,
    provideRenderers,
    promotionsUrl,
    helpPageUrl,
    openFeedbackTopic,
    openLink,
    version,
  }) => ({
    jurisdiction: productConfig.jurisdiction,
    langCode: locale,
    vertical,
    provideRenderers,
    promotionsUrl,
    helpPageUrl,
    openFeedbackTopic,
    openLink,
    environment: productConfig.env,
    version,
  }),

  '@feature/recaptcha-service': ({ siteKey }) => ({
    siteKey,
  }),

  '@feature/registration-flow': ({
    productConfig,
    locale,
    productSource,
    engagementName,
    isLivePersonSupported,
    isNewRegistration,
    nui,
    livePersonFallbackUrl,
    livePersonIosFallbackUrl,
  }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    locale: locale,
    regSource: productSource,
    engagementName,
    isLivePersonSupported,
    isNewRegistration,
    nui,
    livePersonFallbackUrl,
    livePersonIosFallbackUrl:
      typeof livePersonIosFallbackUrl === 'undefined' ? livePersonFallbackUrl : livePersonIosFallbackUrl,
  }),

  '@feature/obsession-service': ({ productConfig, locale, enableOAuthAuthorization, shouldFollowLogout }) => ({
    obSessionServiceUrl: `${productConfig.OBSessionServiceBaseUrl}/${getObLocale(locale)}`,
    casUrl: productConfig.casUrl,
    enableSessionManagerProxyMode: enableOAuthAuthorization ? isOpenBetSessionProxyModeEnabled() : false,
    shouldFollowLogout,
  }),

  '@feature/hidden-markets-widget-flow': ({ productConfig, locale }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    locale: locale,
  }),

  '@feature/promotions-banners-flow': ({ productConfig, locale, vertical }) => ({
    environment: productConfig.env,
    jurisdiction: productConfig.jurisdiction,
    locale,
    vertical,
  }),

  '@feature/smart-banners-flow': ({ pbaKey, webKey, autostart, initDelayMs, bannerZIndex, bannerContainerQuery }) => ({
    pbaKey,
    webKey,
    autostart,
    initDelayMs,
    bannerZIndex,
    bannerContainerQuery,
  }),
};
