import React from 'react';
import ReactDOM from 'react-dom';
import { ACCOUNT_ACTION_IDS } from '@central-products/interface-account-actions';
import { feature as loginFlowFeature } from '@central-products/login-flow';
import { Modal } from '../../utils/components/Modal';

const loginButtonConfig = {
  type: '@sitebase/login-button',
  data: {
    id: 'loginButtonId',
  },
  children: [],
};

export const loginPlugin = {
  beforeRender({ header }) {
    header.actions.children.push(loginButtonConfig);
  },
  beforeBootstrap({ addBootstrapperFeature }) {
    addBootstrapperFeature(loginFlowFeature);
  },
  postBootstrap({ bootstrapperFeatures }) {
    let domNode;

    function unmountComponent() {
      if (domNode) {
        ReactDOM.unmountComponentAtNode(domNode);
        document.body.removeChild(domNode);
        domNode = null;
      }
    }

    function renderModalWithIframe(url, outfit) {
      if (!domNode) {
        domNode = document.createElement('div');
        domNode.id = 'cp-action-root';
        document.body.appendChild(domNode);
      }
      ReactDOM.render(<Modal iframeUrl={url} onClose={unmountComponent} outfit={outfit} />, domNode);
    }

    bootstrapperFeatures['account-actions'].onActionRequested(({ actionId, data }) => {
      switch (actionId) {
        case ACCOUNT_ACTION_IDS.LOGIN_START: {
          bootstrapperFeatures['login-flow'].start();
          break;
        }
        case ACCOUNT_ACTION_IDS.RESET_PASSWORD_START: {
          renderModalWithIframe(data, 'full-width');
          break;
        }
        case ACCOUNT_ACTION_IDS.RECOVER_START:
        case ACCOUNT_ACTION_IDS.REOPEN_START: {
          if (data.includes('.es/')) {
            renderModalWithIframe(data, 'top-space');
          } else {
            renderModalWithIframe(data);
          }
          break;
        }
      }
    });
  },
};
