import { ACCOUNT_ACTION_IDS } from '@central-products/interface-account-actions';
import { feature as accountFlagsFeature } from '@central-products/account-flags';
import { feature as accountFlagsFlowFeature } from '@central-products/account-flags-flow';

export const accountFlagsPlugin = {
  beforeBootstrap({ addBootstrapperFeature }) {
    addBootstrapperFeature(accountFlagsFeature);
    addBootstrapperFeature(accountFlagsFlowFeature);
  },
  postBootstrap({ bootstrapperFeatures }) {
    bootstrapperFeatures['account-actions'].onActionRequested(({ actionId, data }) => {
      if (actionId !== ACCOUNT_ACTION_IDS.HARD_FLAG_START && actionId !== ACCOUNT_ACTION_IDS.SOFT_FLAG_START) {
        return;
      }

      bootstrapperFeatures['account-flags-flow'].start(data);
    });
  },
};
